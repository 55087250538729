@import "~bootswatch/dist/superhero/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/superhero/bootswatch";

$navbar-height: 56px;

$navbar-background: theme-color('primary');
.navbar {
    @extend .navbar-dark;
    background-color: $navbar-background;
}

$callstatus-foreground: $white;
$subform-card-color: darken(theme-color('dark'), 3%);

@import '../main.scss';
